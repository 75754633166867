import { OidcClientSettings, UserManager } from 'oidc-client-ts';

export const EaseeClientOidcConfig: OidcClientSettings = {
  authority: `${process.env.REACT_APP_OIDC_AUTHORITY}`,
  client_id: 'easee-firmware-manager-portal',
  redirect_uri: `${window.location.origin}/login`,
};

export const OidcUserManager = new UserManager(EaseeClientOidcConfig);

export const getIdToken = async (): Promise<string> => {
  const user = await OidcUserManager.getUser();

  if (!user) {
    console.error('No authenticated user found');
  }

  return user?.access_token ?? '';
};
